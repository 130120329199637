<template>
	<div style="padding: 30px;">
		<el-form :inline="true" :model="formSearch" ref="formSearch" class="demo-form-inline">
			<el-form-item label="新闻分类名称">
				<el-input placeholder="请输入新闻分类名称"  v-model="formSearch.name" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="是否显示">
				<el-select  placeholder="是否显示" v-model="formSearch.state">
					<el-option value="0" label="全部"></el-option>
					<el-option value="1" label="是"></el-option>
					<el-option value="2" label="否"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getTableDate(1)">查询</el-button>
			</el-form-item>
		</el-form>
		<el-button type="primary" @click="handleAdd()">添加新闻分类</el-button>
		<el-dialog title="新闻分类" :visible.sync="dialogFormVisible"  width="800px">
			<el-form :model="formInfo" ref="formInfo" :rules="rules">
				<el-form-item label="分类名称" :label-width="formLabelWidth" prop="name">
					<el-input v-model="formInfo.name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="分类简介" :label-width="formLabelWidth" prop="synopsis">
					<el-input v-model="formInfo.synopsis" type="textarea" :autosize="{ minRows:4}"></el-input>
				</el-form-item>
				<el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
					<el-input type="number" v-model="formInfo.sort" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="是否显示" :label-width="formLabelWidth">
					<el-radio v-model="formInfo.state" label="1">是</el-radio>
					<el-radio v-model="formInfo.state" label="2">否</el-radio>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm()">确 定</el-button>
			</div>
		</el-dialog>
		<el-table :data="tableData" border stripe style="width: 100%;margin-top: 20px;">
			<el-table-column type="index" width="120" label="编号" align="center"></el-table-column>
			<el-table-column prop="name" label="分类名称" align="center"></el-table-column>
			<el-table-column prop="state" label="是否显示" align="center" :formatter="stateChange"></el-table-column>
			<el-table-column prop="sort" label="排序" align="center"></el-table-column>
			<el-table-column prop="date" label="操作" align="center">
				<template slot-scope="scope">
					<el-button
						size="mini"
						@click="handleEdit(scope)">编辑</el-button>
					<el-button
						size="mini"
						type="danger"
					@click="handleDelete(scope)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="margin-top: 10px;"
			background
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="tablePageInfo.currentPage"
			:page-sizes="[10, 20,50,100]"
			:page-size="tablePageInfo.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="tablePageInfo.total">
		</el-pagination>
	</div>
</template>
<script>
export default {
	data() {
		return {
			dialogFormVisible: false,
			formSearch: {
				name: '',
				state:'0',
				page:1,
				limit:10
			},
			formInfo: {
				name: '',
				sort: '',
				state:'1',
				synopsis:'',//新闻简介
			},
			formLabelWidth: '120px',
			rules: {
				name: [{required: true}],
				sort: [{required: true}],
				synopsis: [{required: true}],
			},
			tableData:[],
			tablePageInfo:{
				pageSize:10,//每页的条数
				total:0,//总条数
				currentPage:1,//当前页
			},
			buttonFun:'addSort'
		}
	},
	methods: {
		submitForm() {
			var that=this;
			this.$refs['formInfo'].validate((valid) => {
				if (valid) {
					this.dialogFormVisible=false;
					this.$axios.post(this.baseUrl+'admin/article/'+this.buttonFun,that.formInfo).then(response=>{
						if(response.data.code=='200'){
							that.getTableDate();
						}
						that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
					}).catch(function (error) {window.console.log(error)});//ajax请求失败
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		getTableDate:function(res){
			var that=this;
			if(res!=''){that.formSearch.page=1;}//点击查询，恢复第一页
			this.$axios.post(this.baseUrl+'admin/article/searchSort',that.formSearch).then(response=>{
				if(response.data.code=='200'){
					that.tablePageInfo.total=response.data.data.count;
					that.tableData=response.data.data.res
				}
				else{that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});}
			}).catch(function (error) {window.console.log(error)});//ajax请求失败
		},
		handleAdd() {
			this.dialogFormVisible=true;
			this.formInfo={
				name: '',
				sort: '',
				state:'1',
				synopsis:'',//新闻简介
			},
			this.buttonFun='addSort';
			if(this.$refs['formInfo'] !== undefined){
				this.$refs['formInfo'].resetFields();
			}
		},
		handleEdit(scope) {
			if(this.$refs['formInfo'] !== undefined){
				this.$refs['formInfo'].resetFields();
			}
			this.buttonFun='updateSort'
			this.dialogFormVisible=true;
			this.formInfo={
				newsSortId:scope.row.news_sort_id,
				name: scope.row.name,
				sort:scope.row.sort,
				state:scope.row.state.toString(),
				synopsis:scope.row.synopsis,
			}
		},
		handleDelete(scope) {
			var that=this;
			this.$axios.get(this.baseUrl+'admin/article/deleteSort?newsSortId='+scope.row.news_sort_id).then(response=>{
				if(response.data.code=='200'){that.getTableDate();}
				that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
			}).catch(function (error) {window.console.log(error)});//ajax请求失败
		},
		handleSizeChange(val) {
			this.formSearch.limit=val;
			this.getTableDate('');
		},
		handleCurrentChange(val) {
			this.formSearch.page=val;
			this.getTableDate('');
		},
		stateChange(row){if(row.state==1){return '是'}else{return '否'}},
	},
	mounted:function(){
		this.getTableDate('')
	}
}
</script>

<style scoped>
</style>
